var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "320" },
      model: {
        value: _vm.show,
        callback: function($$v) {
          _vm.show = $$v
        },
        expression: "show"
      }
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", { staticClass: "headline" }, [
            _vm._v(_vm._s(_vm.options.title))
          ]),
          _c("v-card-text", [_vm._v(_vm._s(_vm.options.text))]),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _vm._l(Object.keys(_vm.options.buttons), function(key) {
                return _c(
                  "v-btn",
                  {
                    key: key,
                    attrs: {
                      color: _vm.options.buttons[key].attr.color,
                      text: _vm.options.buttons[key].attr.text
                    },
                    on: {
                      click: function($event) {
                        return _vm.$emit(_vm.options.buttons[key].event)
                      }
                    }
                  },
                  [_vm._v(" " + _vm._s(_vm.options.buttons[key].label) + " ")]
                )
              })
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }