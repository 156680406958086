<template>
  <CouponsList />
</template>

<script>
// @ is an alias to /src
import CouponsList from '@/components/coupons/list.vue';

export default {
  name: 'Coupons',
  components: {
    CouponsList,
  },
};
</script>

<style scoped>

</style>
