<template>
<div>
  <v-breadcrumbs></v-breadcrumbs>
  <v-alert
    class="error-alert"
    v-model="error"
    transition="fade-transition"
    type="error"
  >
    {{errorMsg}}
  </v-alert>
  <v-data-table
    :headers="headers"
    :items="coupons"
    :loading="loading"
    sort-by="calories"
    class="elevation-1"
    :search="search"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>Coupons</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          prepend-inner-icon="fa-search"
          label="Search"
          single-line
          hide-details
          clearable
          class="mx-4"
        ></v-text-field>
        <v-dialog v-model="dialog" persisitent max-width="500px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark v-bind="attrs" v-on="on"
              ><v-icon dark left> fa-tags </v-icon>
              New Coupon
            </v-btn>
          </template>
          <v-card>
            <v-form
              ref="form"
              v-model="valid"
              lazy-validation
            >
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        required
                        v-model="editedItem.label"
                        label="Name"
                        :rules="[v => !!v || 'Name is required']"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        required
                        v-model="editedItem.code"
                        label="Code"
                        :rules="[v => !!v || 'Code is required']"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-select
                          :items="typeOptions"
                          v-model="editedItem.type"
                          label="Type"
                      ></v-select>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        required
                          v-model="editedItem.discount"
                          :prefix="getPrefix"
                          :suffix="getSuffix"
                          type="number"
                          label="Discount"
                          hint="Discounts apply to order total including shipping"
                          :rules="discountRules"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-menu
                        v-model="active_at_datepicker"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            required
                            v-model="activeAtDateDisplay"
                            label="Active Starting"
                            prepend-icon="fa-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            :rules="[v => !!v || 'Active Starting Date is required']"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="activeAtDate"
                          @input="active_at_datepicker = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-menu
                          v-model="active_at_timepicker"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            required
                            v-model="activeAtTimeDisplay"
                            label="At"
                            prepend-icon="fa-clock-o"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            :rules="[v => !!v || 'Active From Time is required']"
                          ></v-text-field>
                        </template>
                        <v-time-picker
                            v-model="activeAtTime"
                            :disabled="activeAtTimeDisabled"
                            landscape
                            ampm-in-title
                            @change="active_at_timepicker = false"
                        ></v-time-picker>
                      </v-menu>
                    </v-col>

                    <v-col cols="12" sm="6">
                      <v-menu
                          v-model="expire_at_datepicker"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            required
                            v-model="expireAtDateDisplay"
                            label="Expires On"
                            prepend-icon="fa-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            :rules="[v => !!v || 'Expire At Date is required']"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="expireAtDate"
                          @input="expire_at_datepicker = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-menu
                        v-model="expire_at_timepicker"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            required
                            v-model="expireAtTimeDisplay"
                            label="At"
                            prepend-icon="fa-clock-o"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            :rules="[v => !!v || 'Expire At Time is required']"
                          ></v-text-field>
                        </template>
                        <v-time-picker
                          v-model="expireAtTime"
                          :disabled="expireAtTimeDisabled"
                          landscape
                          ampm-in-title
                          @change="expire_at_timepicker = false"
                        ></v-time-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="close"> Cancel </v-btn>
                <v-btn color="primary" @click="save"> Save </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>
        <Dialog
          v-bind:options="dialogDelete.options"
          v-model="dialogDelete.show"
          v-on="{
            cancel: closeDelete,
            confirm: deleteItemConfirm
          }"
        />
      </v-toolbar>
    </template>
    <template v-slot:[`item.type`]="{ item }">
      <v-chip small :color="chipColor(item)">{{item.type === 'percentage' ? '%' : '$'}}</v-chip>
    </template>
    <template v-slot:[`item.discount`]="{ item }">
      {{ formatDiscount(item.type, item.discount) }}
    </template>
    <template v-slot:[`item.active_at`]="{ item }">
      {{item.active_at | dateTime}}
    </template>
    <template v-slot:[`item.expire_at`]="{ item }">
      {{item.expire_at | dateTime}}
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)"> fa-pencil </v-icon>
      <v-icon small @click="deleteItem(item)"> fa-trash </v-icon>
    </template>
    <template v-slot:[`item.event_note`]="{ item }">
      {{item.event_note | stripHtml}}
    </template>
    <template v-slot:no-data>
      <br />
      <h2>You don't have any coupons yet.</h2>
      <h4>Create one by clicking "New Coupon" in the upper right-hand corner.</h4>
      <br />
    </template>
  </v-data-table>
  </div>
</template>

<script>
import COUPONS from '../../graphql/Coupons/Coupons.gql';
import COUPONS_CREATE from '../../graphql/Coupons/CouponsCreate.gql';
import COUPONS_DELETE from '../../graphql/Coupons/CouponsDelete.gql';
import COUPONS_EDIT from '../../graphql/Coupons/CouponsEdit.gql';
import Dialog from '@/components/common/Dialog.vue';
import dayjs from 'dayjs';


export default {
  data: () => ({
    dialog: false,
    dialogDelete: {
      show: false,
      options: {
        title: 'Are you sure?',
        text: 'Are you sure you want to delete this coupon?',
        buttons: {
          cancel: {
            label: 'Cancel',
            event: 'cancel',
            attr: {
              color: 'default',
              text: true,
            },
          },
          confirm: {
            label: 'OK',
            event: 'confirm',
            attr: {
              color: 'primary',
            },
          },
        },
      },
    },
    active_at_datepicker: false,
    active_at_timepicker: false,
    expire_at_datepicker: false,
    expire_at_timepicker: false,
    loading: true,
    search: '',
    valid: true,
    error: false,
    errorMsg: '',
    headers: [
      { text: 'Name', value: 'label' },
      {
        text: 'Code',
        align: 'start',
        value: 'code',
      },
      { text: 'Type', value: 'type' },
      { text: 'Discount', value: 'discount' },
      { text: 'Active Starting', value: 'active_at' },
      { text: 'Expires On', value: 'expire_at' },
      { text: 'Actions', value: 'actions', sortable: false },
    ],
    editedIndex: -1,
    editedItem: {
      id: null,
      event_id: 0,
      arrangement_id: null,
      code: '',
      label: '',
      type: 'percentage',
      discount: '0',
      active_at: new Date().toISOString(),
      expire_at: '',
    },
    defaultItem: {
      id: null,
      event_id: 0,
      arrangement_id: null,
      code: '',
      label: '',
      type: 'percentage',
      discount: '0',
      active_at: new Date().toISOString(),
      expire_at: '',
    },
    typeOptions: [
      {
        text: 'Percentage',
        value: 'percentage',
      },
      {
        text: 'Fixed Amount',
        value: 'flat-amount',
      },
    ],
  }),

  apollo: {
    coupons: {
      query: COUPONS,
      update( { coupons } ) {
        this.loading = false;
        return coupons;
      },
    },  
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'New Coupon' : 'Edit Coupon';
    },
    showDialogDelete() {
      return this.dialogDelete.show;
    },
    activeAtDateDisplay() {
      if ('' === this.editedItem.active_at) {
        return '';
      }

      return dayjs(this.editedItem.active_at).format('MM/DD/YYYY');
    },
    activeAtDate: {
      get: function() {
        if ('' === this.editedItem.active_at) {
          return '';
        }

        return dayjs(this.editedItem.active_at).format('YYYY-MM-DD');
      },
      set: function(date) {
        if ('' === date) {
          this.editedItem.active_at = '';
          return;
        }
        let time = '' === this.editedItem.active_at ? dayjs() : dayjs(this.editedItem.active_at);
        time = time.format('HH:mm');

        this.editedItem.active_at = dayjs( date + ' ' + time ).toISOString();
      },
    },
    activeAtTimeDisplay() {
      if ('' === this.editedItem.active_at) {
        return '';
      }

      return dayjs(this.editedItem.active_at).format('hh:mm a');
    },
    activeAtTimeDisabled() {
      return '' === this.editedItem.active_at;
    },
    activeAtTime: {
      get: function() {
        if ('' === this.editedItem.active_at) {
          return '';
        }

        return dayjs(this.editedItem.active_at).format('HH:mm');
      },
      set: function(time) {
        if ('' === this.editedItem.active_at) {
          this.editedItem.active_at = '';
          return;
        }

        this.editedItem.active_at = dayjs( dayjs(this.editedItem.active_at).format('YYYY-MM-DD') + ' ' + time ).toISOString();
      },
    },
    expireAtDateDisplay() {
      if ('' === this.editedItem.expire_at) {
        return '';
      }

      return dayjs(this.editedItem.expire_at).format('MM/DD/YYYY');
    },
    expireAtDate: {
      get: function() {
        if ('' === this.editedItem.expire_at) {
          return '';
        }

        return dayjs(this.editedItem.expire_at).format('YYYY-MM-DD');
      },
      set: function(date) {
        if ('' === date) {
          this.editedItem.expire_at = '';
          return;
        }
        let time = '' === this.editedItem.expire_at ? dayjs() : dayjs(this.editedItem.expire_at);
        time = time.format('HH:mm');

        this.editedItem.expire_at = dayjs( date + ' ' + time ).toISOString();
      },
    },
    expireAtTimeDisplay() {
      if ('' === this.editedItem.expire_at) {
        return '';
      }

      return dayjs(this.editedItem.expire_at).format('hh:mm a');
    },
    expireAtTimeDisabled() {
      return '' === this.editedItem.expire_at;
    },
    expireAtTime: {
      get: function() {
        if ('' === this.editedItem.expire_at) {
          return '';
        }

        return dayjs(this.editedItem.expire_at).format('HH:mm');
      },
      set: function(time) {
        if ('' === this.editedItem.expire_at) {
          this.editedItem.expire_at = '';
          return;
        }

        this.editedItem.expire_at = dayjs( dayjs(this.editedItem.expire_at).format('YYYY-MM-DD') + ' ' + time ).toISOString();
      },
    },
    getPrefix() {
      return this.editedItem.type === 'flat-amount' ? '$' : '';
    },
    getSuffix() {
      return this.editedItem.type === 'percentage' ? '%' : '';
    },
    discountRules() {
      const rules = [
        v => !!v || 'Discount is required', 
        v => (v && Number(v) > 0) || 'Discount must be greater than 0',
      ];

      if (this.editedItem.type === 'percentage') {
        const noDecimalRule = v => (v && Number.isInteger( Number(v) ) && !v.includes('.') ) || 'A Percentage coupon discount cannot contain decimals - whole numbers only.';
        const rangeRule = v => (v && Number(v) >= 1 && Number(v) <= 100 ) || 'A Percentage discount must be between 1-100.';
        rules.push(noDecimalRule, rangeRule);
      } else {
        const twoDecimalMaxRule = v => (v && (!v.includes('.') || v.split('.')[1].length <= 2)) || 'A Fixed Amount coupon discount must contain two digits after the decimal only.';
        rules.push(twoDecimalMaxRule);
      }

      return rules;
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    error() {
      // Auto-hide the error alert after 2sec
      setTimeout(() => {
        this.resetError();
      }, 2000);
    },
    showDialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
    },

    formatFlatAmount(amount) {
      const num = Number(amount);
      return num.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    },

    formatDiscount(type, discount) {
      return type === 'percentage' ? `${discount}%` : `$${this.formatFlatAmount(discount)}`;
    },

    editItem(item) {
      this.editedIndex = this.coupons.indexOf(item);
      this.editedItem = JSON.parse(JSON.stringify(item));
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.coupons.indexOf(item);
      this.editedItem = JSON.parse(JSON.stringify(item));
      this.dialogDelete.show = true;
    },

    async deleteItemConfirm() {
      try {
        this.loading = true;

        await this.$apollo.mutate({
          mutation: COUPONS_DELETE,
          variables: {
            id: this.editedItem.id,
          },
          update: (store, result) => {
            return result;
          },
        });

        this.$apollo.queries.coupons.refetch();
      } catch (e) {
        this.errorMsg = e;
        this.error = true;
      }

      this.loading = false;
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.resetValidation();
      this.$nextTick(() => {
        this.editedItem = JSON.parse(JSON.stringify(this.defaultItem));
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete.show = false;
      this.$nextTick(() => {
        this.editedItem = JSON.parse(JSON.stringify(this.defaultItem));
        this.editedIndex = -1;
      });
    },

    async save() {
      this.loading = true;

      if ( !this.validate() ) {
        this.loading = false;
        return;
      }

      try {
        if (this.editedIndex > -1) {
          /* Edit Coupon */
          // Database update
          const { data: { editedCouponId } } = await this.$apollo.mutate({
            mutation: COUPONS_EDIT,
            variables: {
              input: {
                id: this.editedItem.id,
                event_id: this.editedItem.event_id,
                arrangement_id: this.editedItem.arrangement_id,
                code: this.editedItem.code,
                label: this.editedItem.label,
                type: this.editedItem.type,
                discount: this.editedItem.discount,
                active_at: this.editedItem.active_at,
                expire_at: this.editedItem.expire_at,
              },
            },
          });

          // UI update
          Object.assign(this.coupons[this.editedIndex], this.editedItem);
  
          console.log('editCoupon editedCouponId: ', editedCouponId);
        } else {
          /* Create Coupon */
          // Database update
          const { data: { createdCouponId } } = await this.$apollo.mutate({
            mutation: COUPONS_CREATE,
            variables: {
              input: {
                event_id: this.editedItem.event_id,
                arrangement_id: this.editedItem.arrangement_id,
                code: this.editedItem.code,
                label: this.editedItem.label,
                type: this.editedItem.type,
                discount: this.editedItem.discount,
                active_at: this.editedItem.active_at,
                expire_at: this.editedItem.expire_at,
              },
            },
          });

          // UI update
          this.editedItem.id = createdCouponId;
          this.coupons.push(this.editedItem);
          
          console.log('createCoupon result: ', createdCouponId);
        }
      } catch (e) {
        this.showErrorWith(e);
      }
      this.loading = false;
      this.close();
    },

    resetError() {
      this.error = false;
      this.errorMsg = '';
    },

    showErrorWith(error) {
      this.errorMsg = error;
      this.error = true;
    },

    validate () {
      return this.$refs.form.validate();
    },

    reset () {
      return this.$refs.form.reset();
    },

    resetValidation () {
      return this.$refs.form.resetValidation();
    },
        
    chipColor(item) {
      const typeOptions = {
        'published' : 'success',
        'archived'  : 'error',
      };
      
      return typeOptions[item.type];
    },
  },
  components: {
    Dialog,
  },
};
</script>

<style scoped>

.theme--light.v-chip.published{
  background-color: #91CD9C;
  color: #fff;
}

.theme--light.v-chip.archived{
  background-color: #EE5343;
  color: #fff;
}

.error-alert {
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: 350px;
  text-overflow: wrap;
  z-index: 100;
}
</style>
