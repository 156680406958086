<template>
  <!-- This still needs a lot of work! Just the start of a reusable dialog. -->
  <!-- This only works currently with the Delete dialog in list.vue -->
  <v-dialog v-model="show" persistent max-width="320">
    <v-card>
      <v-card-title class="headline">{{ options.title }}</v-card-title>
      <v-card-text>{{ options.text }}</v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          v-for="key in Object.keys(options.buttons)"
          :key="key"
          :color="options.buttons[key].attr.color"
          :text="options.buttons[key].attr.text"
          @click="$emit(options.buttons[key].event)"
        >
          {{ options.buttons[key].label }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    options: Object,
    value: Boolean,
  },
  computed: {
      show: {
        get () { return this.value; },
      },
  },
};
</script>

<style scoped>
</style>